body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


/*timepicker style*/
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  padding-left: 0;
  padding-right: 0;
  width: 100px;
}

.react-datepicker__input-container {
  width: inherit;
}

.react-datepicker-wrapper {
  width: 100%;
}


/*home page styles*/

.masthead {
  display: flex;
  align-items: center;
  background-image: linear-gradient(
          135deg,
          rgb(24, 42, 115) 0%,
          rgb(33, 138, 174) 69%,
          rgb(32, 167, 172) 89%
  ) !important;
  height: 100vh;
}

/* .masthead.segment {
  min-height: 700px;
  padding: 1em 0 !important;
} */

.masthead .ui.menu .ui.button,
.ui.menu a.ui.inverted.button {
  margin-left: 0.5em;
}

.masthead h1.ui.header {
  font-size: 4em;
  font-weight: normal;
}

.masthead h2 {
  font-size: 1.7em;
  font-weight: normal;
}

/* .footer.segment {
  padding: 5em 0;
} */

.secondary.inverted.pointing.menu {
  border: none;
}

/*end home page styles*/

/* navbar styles */

.ui.menu .item img.logo {
  margin-right: 1.5em;
}

.ui.fixed.menu {
  background-image: linear-gradient(
          135deg,
          rgb(137,198,66) 0%,
          rgb(137,198,66) 69%,
          rgb(137,198,66) 89%
  ) !important;
}

.ui.main.container,
.main.segment {
  margin-top: 7em;

}

.ui.center.aligned.segment.attendance-preview {
  background-color: #f5f5f5;
}

.masthead .ui.menu .ui.button,
.ui.menu a.ui.inverted.button {
  margin-left: 0.5em;
}

.ui.menu .item>img:not(.ui) {
  margin-right: 1.5em !important;
}

.ui.menu:not(.vertical) .item>.button {
  margin-left: 0.5em;
}

/*chat comments*/

.ui.comments .comment .comments {
  padding-bottom: 0 !important;
  padding-left: 2em !important;
}

/* dropzone styles */
.dropzone {
  border: dashed 3px #eee;
  border-radius: 5px;
  padding-top: 30px;
  text-align: center;
  /* min-height: 236px; */
}

.dropzone--isActive {
  border: dashed 3px green;
}

.ui.grid.row.blue.column {
  color: #000;
}
.ui.grid>.row>.blue.column {
  color: #000;
}

.searchInput > div{
  width: 100%;
}
